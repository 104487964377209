.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.green {
  color: #179a58;
}

.flashing_red {
  color: #ff0000;
  animation: flashing_red 1s linear infinite;
}

.red {
  color: #ff0000;
}

@keyframes flashing_red {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

body {
  margin: 0;
  padding: 0;
  touch-action: pan-y, pan-x;
}


h3,
h2,
h1,
p {
  font-family: Helvetica Neue, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*  YOLO */
.overlays {
  z-index: -100 !important;
}

.mapClass {
  border: 1px red;
}

#right {
  width: 70vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.mapboxgl-popup-content.mapboxgl-popup-content {
  width: 90vw;
  max-width: 400px;
}

.mapboxgl-popup-close-button {
  font-size: 15px;
}

#search_overlay {
  position: absolute;
  top: 10px;
  left: 10px;

  /* Don't attach to right side, but leave a gap */
  margin-right: 10px;
  /* Show above the map, but below the pin popup */
  z-index: 1;

  padding: 5px 10px;

  background-color: rgba(255, 255, 255, 0.8);
  border: 3px solid gray;
  border-radius: 10px;
}

#search_overlay h2,
#search_overlay h3 {
  margin-top: 0px;
  margin-bottom: 3px;
}

#search_container input {
  margin-left: 10px;
  width: 310px;
  height: 30px;
  font-size: 20px;
}

h2 {
  margin-top: 0px;
}

.mapboxgl-popup-content.mapboxgl-popup-content {
  max-width: 300px;
}

.mapboxgl-popup {
  /* Show popup over the top left info. */
  z-index: 2;
}

figcaption {
  margin-left: 30px;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

td {
  padding-left: 4px;
  border-left: 1px solid rgb(192, 192, 192);
}
tr {
  border-bottom: 1px solid rgb(192, 192, 192);
}

td:first-child,
th:first-child {
  border-left: none;
  width: 110px;
}
tr:last-child {
  border-bottom: none;
}
table {
  margin-bottom: 0px;
}

button {
  border-radius: var(--border-radius);
  display: inline-block;
  padding: 0.2em 1.45em;
  margin: 0.1em;
  margin-right: 0.1em;
  border: 0.15em solid #ccc;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Segoe UI, Roboto, sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #ccc;
  text-align: center;
  position: relative;
}



@keyframes fadeOpacity {
  0% {
  opacity: 0; 
 }
 100% {
  opacity: 1; 
} 
}
